import Vue from "vue";

/*TITLE*/
document.title = "Viviendas Alfaz del Pi | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Viviendas Alfaz del Pi";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Viviendas Alfaz del Pi";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-duplex-forest-naturalzinckrion-snow-white_web--20230112110114.jpg";
Vue.prototype.$image_kitchen = "salon-apartamento-forest-naturalzinckrion-snow-white_web--20230112110102.jpg";
Vue.prototype.$image_bath1 = "banyo1-apartamento-forest-naturalnewport-beigeold-beige_web--20230112110157.jpg";
Vue.prototype.$image_bath2 = "banyo2-apartamento-forest-naturalnewport-beigeold-beige_web--20230112110118.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "0-facade--20221124091620.jpeg";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-duplex-forest-naturalzinckrion-snow-white_web--20230112110114.jpg",
  },
  {
    src: "salon-duplex-forest-naturalbisonkrion-snow-white_web--20230112110130.jpg",
  },
  {
    src: "salon-duplex-bottega-calizablanco-nubolxtone-bottega-caliza_web--20230112110142.jpg",
  },
  {
    src: "salon-duplex-bottega-aceroblanco-nubolxtone-bottega-acero_web--20230112110153.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "salon-apartamento-forest-naturalzinckrion-snow-white_web--20230112110102.jpg",
  },
  {
    src: "salon-apartamento-forest-naturalbisonkrion-snow-white_web--20230112110110.jpg",
  },
  {
    src: "salon-apartamento-bottega-calizablanco-nubolxtone-bottega-caliza_web--20230112110126.jpg",
  },
  {
    src: "salon-apartamento-bottega-aceroblanco-nubolxtone-bottega-acero_web--20230112110135.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo1-apartamento-forest-naturalnewport-beigeold-beige_web--20230112110157.jpg",
  },
  {
    src: "banyo1-apartamento-bottega-calizanewport-beigeold-beige_web--20230112110105.jpg",
  },
  {
    src: "banyo1-apartamento-bottega-aceronewport-beigeold-beige_web--20230112110111.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo2-apartamento-forest-naturalnewport-beigeold-beige_web--20230112110118.jpg",
  },
  {
    src: "banyo2-apartamento-bottega-calizanewport-beigeold-beige_web--20230112110125.jpg",
  },
  {
    src: "banyo2-apartamento-bottega-aceronewport-beigeold-beige_web--20230112110131.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
  {
    src: "0-facade--20221124091620.jpeg",
  },
  {
    src: "1-facade--20221124091620.jpeg",
  },
  {
    src: "2-facade--20221124091620.jpeg",
  },
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/ALFAZ_PI/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/X-ls_66FlRk";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20xirivella%20alicante%2003580",
    text: "Calle Xirivella, Alicante, 03580",
  },
];

Vue.prototype.$showroom = [
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:+34638042482",
    text: "+34638042482",
  },
  {
    icon: "mdi-email",
    link: "mailto:comercial@mrmarti.es",
    text: "comercial@mrmarti.es",
  },
];
